export const Assets = {
  images: {
    companyLogo: require("../../images/png/company-logo-removebg-preview.png"),
    // companyLogo: require("../../images/png/droidLogo.png"),
    companyBanner: require("../../images/png/droid banner.png"),
    homeBannerone: require("../../images/png/home-banner-one.png"),
    homeBannertwo: require("../../images/png/home-banner-two.png"),
    aboutImage: require("../../images/png/about-image.png"),

    joinImageOne: require("../../images/png/join-image-one.png"),
    joinImageTwo: require("../../images/png/join-image-two.png"),
    serviceImage: require("../../images/png/servic-image.png"),
    serviceImageTwo: require("../../images/png/service-image-two.png"),
    serviceImageThree: require("../../images/png/service-image-three.png"),
    websiteImage: require("../../images/png/website-image.png"),

    // what we do images
    softwareWhatWeDo: require("../../images/png/software-what-we-do.png"),
    animationWhatWeDo: require("../../images/png/animation-what-we-do.png"),
    techTrainingWhatWeDo: require("../../images/png/tech-training-what-we-do.png"),
    droneWhatwedo: require("../../images/png/drone service-what-we-do.png"),
    equipmentwhatwedo: require("../../images/png/equipment-setup-what-we-do.png"),

    // software page
    softwareBannerImage: require("../../images/png/sotware dev 1.png"),
    benefitillustratorOne: require("../../images/png/benefit-illustrator-image-one.png"),
    benefitillustratorTwo: require("../../images/png/benefit-illustrator-image-two.png"),

    comapanyAboutImage1: require("../../images/png/aboutimage1.png"),
    companyLogoNoBg: require("../../images/png/company-logo-removebg-preview.png"),
    coding: require("../../images/png/coding.png"),
    statistics: require("../../images/png/statistics.png"),
    ourServices: require("../../images/png/our services.png"),
    tour: require("../../images/png/travel-6493621_640.png"),
    staffBg: require("../../images/png/allstaff.png"),
    mobiledev: require("../../images/png/mobile developer.png"),
    softwaredesign: require("../../images/png/software-design.png"),
    webdesign: require("../../images/png/web design d.png"),
    customercare: require("../../images/png/customer care.png"),
    background1: require("../../images/png/background1.jpg"),
    background2: require("../../images/png/Digital-experience.png"),
    manLearning: require("../../images/png/Man-learning-online.jpg"),
    contactarealogo: require("../../images/png/i-removebg-preview.png"),
    ccare: require("../../images/png/ccare.png"),
    software: require("../../images/png/image.png"),

    stellaimg: require("../../images/png/stellaImage.jpeg"),
    uzoimg: require("../../images/png/Uzoimage.png"),
    princewill: require("../../images/png/princewil-image.jpg"),

    tech: require("../../images/png/tech.jpg"),
    tech2: require("../../images/png/tech2.webp"),
    tech3: require("../../images/png/tech3.webp"),
    softDev: require("../../images/png/softd.jpg"),
    softDev2: require("../../images/png/soft2.avif"),
    service: require("../../images/png/service2.jpg"),
    softwareBg: require("../../images/png/sd.png"),
    homeBg: require("../../images/png/homepage.png"),

    droid_web: require("../../images/png/droi-product-image.png"),
    reign: require("../../images/png/reign.png"),
    one_one: require("../../images/png/training-image-one.png"),
    uni: require("../../images/png/training-image-three.png"),
    highSchool: require("../../images/png/training-image-two.png"),
    productPageBanner: require("../../images/png/product-page-banner-removebg-preview.png"),
    trainingBannerOne: require("../../images/png/Training-banner-one-removebg-preview.png"),
    trainingBannerTwo: require("../../images/png/traning-ban-two-removebg-preview.png"),
    tarinigBannerThree: require("../../images/png/training-ban-three-removebg-preview.png"),
    trainingIconImage: require("../../images/png/training-icon-image.png"),
    knowledgeCityMobileApp: require("../../images/png/iphone-image-removebg-preview.png"),
    // knowledgeCityMobileAppTwo: require("../../images/png/iPhone 2.png"),
    whatwedoBanner: require("../../images/png/what-we-do-banner-image.png"),

    productCustomerReview: require("../../images/png/product-client-removebg-preview.png"),

    servicesMain: require("../../images/png/what.png"),
    techT: require("../../images/png/techtraining.png"),

    // testImage: require("../../images/png/testimage.png"),
    testImage: require("../../images/png/text-image.png"),
    wDev: require("../../images/png/web dev 1.png"),
    mDev: require("../../images/png/mobile web 1.png"),
    tService: require("../../images/png/tservice-two.png"),
    backEndDev: require("../../images/png/backend dev 1.png"),
    cReviews: require("../../images/png/code reviews 1.png"),
    appAi: require("../../images/png/ogoo-Ai.png"),

    trainingOne: require("../../images/png/train1.png"),
    trainingTwo: require("../../images/png/train2.png"),
    trainingThree: require("../../images/png/train3.png"),
    noChat: require("../../images/png/no-chat.png"),

    maximuspage: require("../../images/png/Maximuspage.jpg"),
    drizzle: require("../../images/png/drizzlepage.jpg"),
    cashBasket: require("../../images/png/newcashbasket.png"),
    knowledgecity: require("../../images/png/knowledge-product-image.png"),
    dome: require("../../images/png/dome.png"),

    // developers page
    developersHeroImage: require("../../images/png/developer-hero-img.png"),

    // Droid Icons
    scheduleLine: require("../../images/png/mingcute_schedule-line.png"),
    phoneChat: require("../../images/png/phone-chat.png"),
    solidPeopleRoof: require("../../images/png/solid_people-roof.png"),
    windowDevTools: require("../../images/png/window-dev-tools.png"),
    streamlineWeb: require("../../images/png/streamline_web.png"),
    arrowUp: require("../../images/png/arrow-up.png"),
    midiPassport: require("../../images/png/mdi_passport.png"),
    badgeOutline: require("../../images/png/badge-outline.png"),
    evaSettings: require("../../images/png/eva_settings-2-fill.png"),
    customerServiceIcon: require("../../images/png/customer-service-01.png"),
    arrowOutline: require("../../images/png/arrow-outlined.png"),
    notificationIcon: require("../../images/png/notification-fill.png"),
    programmingOutline: require("../../images/png/programming-script-code.png"),
    outlineLaptop: require("../../images/png/outlineLaptop.png"),
    lightDatabase: require("../../images/png/light_database.png"),
    mdi2DevTool: require("../../images/png/dev-tools-icon.png"),
    rightArrow: require("../../images/png/arrow-right.png"),
    leftArrow: require("../../images/png/arrow-left.png"),
    fingerprintIcon: require("../../images/png/fingerprint.png"),
    spanAppFill: require("../../images/png/app-fit.png"),
    socialWork: require("../../images/png/healthicons_social-work.png"),
    gridiconChat: require("../../images/png/gridicons_chat.png"),
    cryptoChart: require("../../images/png/crypto-prices.png"),
    learning: require("../../images/png/machine-learning-outlined.png"),
    mdiTransport: require("../../images/png/transportation.png"),
    tradeIcon: require("../../images/png/game-icons_trade.png"),
    submitFill: require("../../images/png/formkit_submit.png"),
    outlineCorrect: require("../../images/png/icon-park-outline_correct.png"),
    arrowDown: require("../../images/png/arrow-down-2-light.png"),
    testimonialIcon: require("../../images/png/testimonial-icon.png"),
    playIcon: require("../../images/png/octicon_play-24.png"),
    surveyIcon: require("../../images/png/land-surveying-icon.png"),
    secureIcon: require("../../images/png/vpc-for-regulated-industries.png"),
    plantIcon: require("../../images/png/akar-icons_plant.png"),
    droneIcon: require("../../images/png/drone-icon.png"),
    cameraIcon: require("../../images/png/hugeicons_camera-video.png"),
    toolsFill: require("../../images/png/ri_tools-fill.png"),
    phoneIcon: require("../../images/png/solar_phone-bold.png"),
    emailIcon: require("../../images/png/ic_round-email.png"),
    addIcon: require("../../images/png/ph_plus-bold.png"),

    // PRODUCT DETAILS PAGE
    knowledgeBannerImage: require("../../images/png/Knowledge-Welcome Page member.png"),
    knowledgeBannerTwo: require("../../images/png/kn-Welcome Page two.png"),
    knwledgeMentorImage: require("../../images/png/knw-Mentor-page.png"),
    cashBasketOne: require("../../images/png/cash-basket-image.png"),
    cashBasketTwo: require("../../images/png/Cashbasket-frame-two.png"),
    cashBasketThree: require("../../images/png/cashbasket-three.png"),
    domeImage: require("../../images/png/dome-banner-one.png"),
    domeBannerTwo: require("../../images/png/Dome-banner-two.png"),
    domeBannerThree: require("../../images/png/Dome-banner-three.png"),

    // WEBSITE BUILDER SECTION
    welcompageheroImage: require("../../images/png/welcome-image-icon-removebg-preview.png"),
    webOverviewBanner: require("../../images/png/web-overview-banner.png"),
    projectImageOne: require("../../images/png/project-image-one.png"),
    projectImageTwo: require("../../images/png/project-image-two.png"),
    projectImageThree: require("../../images/png/project-image-three.png"),
  },
  colors: {
    basic: "#000000",
    light: "#ffffff",
    primary: "#071D6A",
    secondary: "#479BE8",
    tertiary: "#FF6F61",
    flat: "#97A3B6",
    overlay: "#00000080",
    borderColor: "#CCCCCC",
    paragraph: "#BAB8B8",
    backgroundColor: "#F9F9F9",
    substitute: "#864733",
    hoier: "#040711",

    // dark
    darkbasic: "#FFFFFF",
    darklight: "#000000",
    darkprimary: "#FFFFFF",
    darksecondary: "#A6D4FA",
    darktertiary: "#FFAEB9",
    darkoverlay: "#FFFFFF80",
    darkborderColor: "#555555",
    darkparagraph: "#CCCCCC",
    darkbackgroundColor: "#000000",
    darksubstitute: "#FBCC34",
  },
  text: {
    companyName: "D'roid Technologies International",
    companyShortName: "D'roid Technologies",
    companyInitials: "DTI",
    modalTitle: "This is the Modal Heading.",
    modalBody: "This is the Body.",
    appVersion: "v3.5",
  },
};
